import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../images/orange.png"
import { FaRegUserCircle, FaPlus } from "react-icons/fa"
import { GoLocation } from "react-icons/go"
import { MdLibraryBooks } from "react-icons/md"
import NewPinModal from "../components/newpin-modal"
import StreamsList from "../components/streamslist"
import firebase from "../components/auth/fire-base.js"

const DesktopSidebar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const showModal = () => {
    setIsOpen(true)
  }

  const hideModal = () => {
    setIsOpen(false)
  }

  const streams = []

  if (firebase.auth().currentUser) {
    firebase
      .firestore()
      .collection("streams")
      .where(
        "creator",
        "==",
        firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
      )
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          if (doc && doc.data()) {
            streams.concat({
              id: doc.id,
              name: doc.data().name,
              createdAt: doc.data().createdAt,
            })
          }
        })
      })
  }

  return (
    <>
      <div className="app-sidebar">
        <div className="tab logo-wrapper">
          <Link to="/" style={{cursor:'pointer'}}>
            <img src={Logo} className="logo" alt="Cloudpin"/>{" "}
          </Link>
        </div>
        <div className="tab">
          <button className="new-pin" onClick={showModal}>
            {" "}
            <FaPlus /> &nbsp;&nbsp;&nbsp;&nbsp; New Pin
          </button>
        </div>
        <div className="tab sidebar-link-wrapper selected-tab" id="nearby-tab">
          {" "}
          <Link
            to="/home/"
            className="sidebar-link"
            activeStyle={{
              color: "#8e1600",
              backgroundColor: "rgba(230, 82, 57, 0.28)",
              width: "100%",
              display: "block",
            }}
          >
             <span className="sidebar-icon"><GoLocation /></span>
            Nearby
          </Link>
        </div>
        <div className="tab sidebar-link-wrapper" id="pin-tab">
          {" "}
          <Link
            to="/pins/"
            className="sidebar-link"
            activeStyle={{
              color: "#8e1600",
              backgroundColor: "rgba(230, 82, 57, 0.28)",
              width: "100%",
              display: "block",
            }}
          >
             <span className="sidebar-icon"><MdLibraryBooks/></span>My Pins
          </Link>
        </div>
        <div className="tab sidebar-link-wrapper" id="profile-tab">
          {" "}
          <Link
            to="/profile/"
            className="sidebar-link"
            activeStyle={{
              color: "#8e1600",
              backgroundColor: "rgba(230, 82, 57, 0.28)",
              width: "100%",
             
              display: "block",
            }}
          >
            {" "}
            <span className="sidebar-icon"><FaRegUserCircle /></span>Profile
          </Link>
        </div>

        <div className="tab">
          <h6 className="subscription-header">Subscriptions</h6>
          <StreamsList page="subscriptions"></StreamsList>
        </div>
        <div className="tab">
          <h6 className="subscription-header">Streams</h6>
          <StreamsList page="streams"></StreamsList>
        </div>
      </div>

      <NewPinModal hideModal={hideModal} isOpen={isOpen} editMode={false} />
    </>
  )
}

export default DesktopSidebar
