import React from "react"
import { Link } from "gatsby"
import { FaHashtag } from "react-icons/fa"
import firebase from "./auth/fire-base.js"

class StreamsList extends React.Component {
  constructor(props) {
    super(props)
    this.getUserStreams = this.getUserStreams.bind(this)
  }

  state = {
    streams: [],
  }

  getUserStreams() {
    if (this.props.page == "streams") {
      if (firebase.auth().currentUser) {
        firebase
          .firestore()
          .collection("streams")
          .where(
            "creator",
            "==",
            firebase
              .firestore()
              .collection("users")
              .doc(firebase.auth().currentUser.uid)
          )
          .orderBy("createdAt", "desc")
          .onSnapshot(snapshot => {
            this.setState({ streams: [] })

            snapshot.forEach(doc => {
              if (doc && doc.data()) {
                this.setState(prevState => {
                  const streams = this.state.streams.concat({
                    id: doc.id,
                    name: doc.data().name,
                    createdAt: doc.data().createdAt,
                  })

                  return { streams }
                })
              }
            })
          })
      }
    } else {
      if (firebase.auth().currentUser) {
        // Get all subscriptions

        firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .onSnapshot(user => {
            if (user.exists) {
              var subscriptions_array = user.data().subscriptions
              this.setState({ streams: [] }, () => {
                if (subscriptions_array && subscriptions_array.length > 0) {
                  for (var sub of subscriptions_array) {
                    firebase
                      .firestore()
                      .collection("streams")
                      .doc(sub.id)
                      .get()
                      .then(doc => {
                        if (doc && doc.data()) {
                          this.setState(prevState => {
                            const streams = this.state.streams.concat({
                              id: doc.id,
                              name: doc.data().name,
                              createdAt: doc.data().createdAt,
                            })

                            return { streams: streams }
                          })
                        }
                      })
                  }
                }
              })
            }
          })
      }
    }
  }

  componentDidMount() {
    this.isLoggedIn()
  }

  async isLoggedIn() {
    this.setState({ active: false })
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ active: true })
        this.getUserStreams()
      }
    })
  }

  getStreamHTML(streamName) {
    return (
      <li style={{ listStyleType: "none" }} key={"li-" + streamName}>
        <Link
          to={"/streams?stream=" + streamName}
          data={{ stream: streamName }}
          className="sidebar-link"
          key={streamName}
          activeStyle={{
            color: "#8e1600",
            backgroundColor: "rgba(230, 82, 57, 0.28)",
            width: "100%",
            borderRadius: "5px",
            height: "40px",
            display: "block",
          }}
        >
          <FaHashtag /> &nbsp;{streamName}
        </Link>
      </li>
    )
  }

  render() {
    var streams =
      this.state.streams && this.state.streams.length > 0
        ? this.state.streams.map(stream => this.getStreamHTML(stream.name))
        : ""

    return streams
  }
}

export default StreamsList
