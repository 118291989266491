import React, {Component} from 'react'
import {withStyles} from '@material-ui/styles';
import {Box, Typography} from '@material-ui/core';
import clsx from 'clsx'
import { Link } from 'gatsby';
import Auth from '../../Auth'
const auth = new Auth();

const useStyles = {
    root:{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'cetner',
        height: 26,
        textAlign:'center',
        color:'rgb(232, 87, 58)',
        fontSize: 16,
        backgroundColor:'rgb(232, 87, 58, 0.3)'
    }
}

class CountTrial extends Component {
    constructor(props){
        super(props)

        this.state = {
            customer:props.customer,
            remainDay:'',
            currentTime: {
                hours: '',
                minutes: '',
                seconds: ''
            },
            expired: false,
            paymentError: null,
        }
        this.startTime = this.startTime.bind(this);
    }

    

	UNSAFE_componentWillMount() {
		let self = this;
		this.timer = setInterval(() => {
			self.startTime();
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
    }
    
    async componentDidMount(){
        const user = await auth.user();
        this.setState({paymentError: user.paymentError})
    }

	startTime() {
        let today = new Date();
        let oldDay = new Date(this.state.customer.expireDate.seconds * 1000);
        let diffTime = oldDay.getTime() - today.getTime();
        if(diffTime>0){
            this.setState({ remainDay:Math.ceil(diffTime/(3600*24*1000)) });
        } else{
            this.setState({expired: true});
        }
	}


    render(){
        const {classes, ClassName} = this.props;
        return(
            this.state.paymentError?
            <Box className={clsx(classes.root, ClassName)}>
                <div style={{display:'flex'}}>
                    <Typography className={classes.text}>{`There was an error processing payment. Please`}</Typography>
                    <div style={{textDecoration:'none', marginLeft: 20, cursor:'pointer'}} >
                        <strong style={{textDecoration:'none', color:'rgb(232, 87, 58)'}}>Update Payment Information</strong>
                    </div>
                </div>
            </Box>:
            <Box className={clsx(classes.root, ClassName)}>
                {   !this.state.customer.pro && (
                    this.state.expired?
                    <div style={{display:'flex'}}>
                        <Typography className={classes.text}>{`You're now using Cloudpin Basic `}</Typography>
                        <div style={{textDecoration:'none', marginLeft: 20, cursor:'pointer'}} >
                            <Link to="/upgrade" ><strong style={{textDecoration:'none', color:'rgb(232, 87, 58)'}}>Upgrade Now</strong></Link>
                        </div>
                    </div>:
                    <div style={{display:'flex'}}>
                        <Typography className={classes.text}>{`You have ${this.state.remainDay} days left on your trial. `}</Typography>
                        <div style={{textDecoration:'none', marginLeft: 20, cursor:'pointer'}} >
                            <Link to="/upgrade" ><strong style={{textDecoration:'none', color:'rgb(232, 87, 58)'}}>Upgrade Now</strong></Link>
                        </div>
                    </div>
                    )
                }

                {   
                    this.state.customer.pro && (
                    this.state.expired?
                    <div style={{display:'flex'}}>
                        <Typography className={classes.text}>{`You're now using Cloudpin Basic `}</Typography>
                        <div style={{textDecoration:'none', marginLeft: 20, cursor:'pointer'}} >
                            <Link to="/upgrade" ><strong style={{textDecoration:'none', color:'rgb(232, 87, 58)'}}>Upgrade Now</strong></Link>
                        </div>
                    </div>:
                    <div style={{display:'flex'}}>
                        <Typography className={classes.text}>{`You have ${this.state.remainDay} days left on your trial. `}</Typography>
                        <div style={{textDecoration:'none', marginLeft: 20, cursor:'pointer'}} >
                            <Link to="/upgrade" ><strong style={{textDecoration:'none', color:'rgb(232, 87, 58)'}}>Upgrade Now</strong></Link>
                        </div>
                    </div>
                    )
                }
            </Box>
        )
    }
}

export default withStyles(useStyles)(CountTrial);